<template>
  <v-form ref="form">
    <filtro-rapido
       v-model="abrirFiltro"
       ref="filtrosRapidos"
       id="AlterarPrazoVencimentoFiltros__filtro_rapido"
       v-if="carregouMetadados && carregouFiltrosPadroes"
       :campos-formulario="camposFormulario"
       :metadados="metadadosAgrupado"
       :ordenacao-campos="ordenacaoCampos"
       :mostrar-fixar-filtros="false"
       :formulario-filtros="false"
       :ignora-obrigatorios="false"
       grupo-expandido="Ação"
       :setar-filtro-padrao-ao-limpar="true"
       :aplicar-filtro-no-carregamento="true"
       :filtro-padrao="filtroPadrao"
       @FiltroRapido__AplicaFiltros="aplicaFiltros"
       @FiltroRapido_conteinerCarregado="$emit('AlterarPrazoVencimentoFiltros_conteinerCarregado')">
    </filtro-rapido>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '@/shared-components/filtro-rapido/FiltroRapido';

export default {
  name: 'AlterarPrazoVencimentoFiltros',
  components: { FiltroRapido },
  props: {
    value: false,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      apuracaoContratoResource: this.$api.apuracaoContrato(this.$resource),

      abrirFiltro: false,
      carregouFiltrosPadroes: false,
      idContrato: null,
      status: null,
      ordenacaoCampos: [
        {
          campo: 'ano',
          nomeGrupo: 'Período',
        },
        {
          campo: 'grupo_fornecedor',
          nomeGrupo: 'Ação',
        },
        {
          campo: 'prazo_acima_30',
          nomeGrupo: 'Prazo',
        },
      ],
      filtroPadrao: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
    ]),
    camposFormulario() {
      return {
        padrao: [
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            vlrObrigatorio: false,
            tipoCampo: 'LISTA',
            desAtributos: { desabilitado: !!this.idContrato },
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'ano',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
            mascara: '####',
            vlrPadrao: new Date().getFullYear(),
          },
          {
            labelCampo: 'prazo_acima_30',
            nomCampo: this.$t('label.prazo_vencimento_acima_30_dias'),
            tipoCampo: 'BOOLEAN',
          },
        ],
      };
    },
    carregouMetadados() {
      return this.getAcaoMetadado && this.getContratoMetadado;
    },
    metadadosAgrupado() {
      if (!this.carregouMetadados) {
        return {};
      }

      const metadados = { ...this.getContratoMetadado };
      metadados.dicionario = { ...this.getAcaoMetadado.dicionario };
      Object.assign(metadados.dicionario, this.getContratoMetadado.dicionario);

      metadados.mapaCamposDinamicos = { ...this.getAcaoMetadado.mapaCamposDinamicos };
      Object.assign(metadados.mapaCamposDinamicos, this.getContratoMetadado.mapaCamposDinamicos);

      metadados.mapaEntidades = {};
      Object.assign(metadados.mapaEntidades, this.getContratoMetadado.mapaEntidades);

      Object.keys(this.getAcaoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getAcaoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
      });

      Object.keys(this.getContratoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getContratoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
      });

      if (metadados.cabecalho) {
        metadados.cabecalho.concat(...this.getAcaoMetadado.cabecalho);
      } else {
        metadados.cabecalho = this.getAcaoMetadado.cabecalho;
      }
      return metadados;
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setContratoMetadado',
    ]),
    aplicaFiltros(filtrosAplicados = null) {
      let filtros = filtrosAplicados;
      if (!filtrosAplicados) {
        filtros = this.$refs.filtrosRapidos.getValoresDependencias();
      }
      if (!this.$refs.form.validate() || !filtros) {
        return;
      }
      this.$emit('AlterarPrazoVencimentoTabela__AplicaFiltros', filtros);
    },
    buscarFornecedorContrato() {
      if (!this.idContrato) {
        return new Promise((res) => { res(); });
      }
      const { idContrato } = this;
      return this.planejamentoContratoResource.buscarGrupoFornecedorContrato({ idContrato })
        .then((response) => {
          this.filtroPadrao.grupo_fornecedor = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencherFiltroPadrao() {
      Promise
        .all([
          this.buscarFornecedorContrato(),
        ])
        .then(() => {
          this.carregouFiltrosPadroes = true;
        });
    },
  },
  beforeMount() {
    this.idContrato = this.$router.currentRoute.params.idContrato;
    this.status = this.$router.currentRoute.params.status || 'AGUARDANDO_APURACAO';
    this.preencherFiltroPadrao();
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
