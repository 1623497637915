<template>
  <div>
    <v-container pa-0 fluid>
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" >
          <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <v-row justify="end" class="mr-3 my-2">
      <v-menu
        ref="prazo_vencimento_massa"
        :close-on-content-click="false"
        :nudge-width="200"
        v-model="menuAteraPrazoVencimento"
        transition="scale-transition"
        offset-y
        bottom
        class="mr-3">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-on="{ ...tooltip, ...menu }"
                id="btn_altera_prazo_vencimento"
                color="primary">
                <v-icon>event</v-icon>
                {{ $t('label.alterar_prazo_vencimento') }}
              </v-btn>
            </template>
            <span>{{ $t('label.define_prazo_vencimento_contratos_selecionados') }}</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-form ref="form" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    id="novoPrazo"
                    :label="`${$t('label.novo_prazo_vencimento')}`"
                    v-model="novoPrazo"
                    type="number"
                    min="0">
                  </v-text-field>
                  <span v-if="qtdContratosSelecionados === 0" style="color: red;">{{ $t('message.nenhum_registro_selecionado') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelarNovoPrazoVencimento">{{ $t('label.cancelar') }}</v-btn>
            <v-btn
              @click="aplicarNovoPrazoVencimentoLote"
              color="primary"
              @FiltroRapido_conteinerCarregado="$emit('AlterarPrazoVencimentoFiltros_conteinerCarregado')"
            >{{ $t('label.aplicar') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <alterar-prazo-vencimento-filtros
        v-if="exibirFiltro"
        v-model="abrirFiltro"
        ref="componenteFiltros"
        @AlterarPrazoVencimentoTabela__AplicaFiltros="aplicaFiltros"
        @AlterarPrazoVencimentoFiltros__Iniciado="abrirFiltro=false"
        >
      </alterar-prazo-vencimento-filtros>
    </v-row>

    <v-container mt-2 fluid>
      <alterar-prazo-vencimento-tabela
        :filtros="filtros"
        :key="panelKey"
        ref="tabelaPrazo"
        :numero-pagina="numeroPagina"
        @AlterarPrazoVencimentoTabela_atualizarQtdContratosSelecionados="atualizarQtdContratosSelecionados">
      </alterar-prazo-vencimento-tabela>
      <v-col class="text-right pr-0">
        <v-btn @click="cancelar">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" @click="confirmarNovoPrazoVencimento" class="ml-3">{{ $t('label.salvar') }}</v-btn>
      </v-col>
    </v-container>
    <confirm
      ref="modalConfirm"
      max-width="600"
      :message="$tc('message.alteracao_prazo_vencimento')"
      @agree="salvarNovoPrazoVencimento"></confirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { alterarPrazoVencimento } from '@/common/resources/planejamento-contrato-listagem';
import AlterarPrazoVencimentoFiltros from '@/spa/planejamento-contrato/alterar-prazo-vencimento/AlterarPrazoVencimentoFiltros';
import AlterarPrazoVencimentoTabela from '@/spa/planejamento-contrato/alterar-prazo-vencimento/AlterarPrazoVencimentoTabela';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  name: 'AlterarPrazoVencimento',
  components: {
    Confirm,
    AlterarPrazoVencimentoTabela,
    AlterarPrazoVencimentoFiltros,
  },
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),

      resource: this.$api.apuracaoContrato(this.$resource),
      abrirFiltro: false,
      filtrosCarregador: false,
      filtros: {},
      tamanhoFiltros: 0,
      eventoClickCheckbox: null,
      menuAteraPrazoVencimento: false,
      dataContabilizacao: new Date().toJSON().substr(0, 10),
      dataContabilizacaoFormatada: null,
      novoPrazo: null,
      novoPrazoAtual: null,
      exibirFiltro: false,
      panelKey: 0,
      qtdContratosSelecionados: 0,
      numeroPagina: 0,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  methods: {
    cancelar() {
      this.$router.push({ name: 'planejamento-contrato' });
    },
    aplicaFiltros(filtros) {
      this.filtros = filtros;
      const zeraPaginacao = true;
      setTimeout(() => this.$refs.tabelaPrazo.buscarContratos(zeraPaginacao));
    },
    cancelarNovoPrazoVencimento() {
      this.menuAteraPrazoVencimento = false;
      this.novoPrazo = null;
    },
    confirmarNovoPrazoVencimento() {
      const contratosSelecionados = this.$refs.tabelaPrazo.buscaContratosSelecionados();
      if (!contratosSelecionados || !contratosSelecionados.length) {
        this.$toast(this.$t('message.nenhum_registro_selecionado'));
      } else {
        setTimeout(() => this.$refs.modalConfirm.open());
      }
    },
    salvarNovoPrazoVencimento() {
      const contratosSelecionados = this.$refs.tabelaPrazo.buscaContratosSelecionados();
      alterarPrazoVencimento({}, this.$resource, contratosSelecionados)
        .then(() => {
          this.$toast(this.$t('message.prazo_vencimento_alterado_sucesso'));
          this.$refs.tabelaPrazo.limpaSelecionados();
          this.$refs.componenteFiltros.aplicaFiltros();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aplicarNovoPrazoVencimentoLote() {
      this.$refs.tabelaPrazo.aplicarNovoPrazoVencimentoLote(this.novoPrazo);
      this.menuAteraPrazoVencimento = false;
      this.novoPrazo = null;
    },
    setaDataFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.dataContabilizacao);
    },
    atualizarQtdContratosSelecionados(qtdContratosSelecionados = 0) {
      this.qtdContratosSelecionados = qtdContratosSelecionados;
    },
  },
  mounted() {
    this.exibirFiltro = true;
  },
};
</script>
